/**
 * Created by vonsh on 2019/3/27
 */
/* eslint-disable*/

let bindFn
let isFirstLoad = true
const triggerEvts = ['WeixinJSBridgeReady', 'click']

// 解除事件绑定
const removeListers = (el = document, handler) => {
  triggerEvts.forEach((evt) => el.removeEventListener(evt, handler))
}
// 绑定事件
const addListers = (el = document, handler) => {
  triggerEvts.forEach((evt) => el.addEventListener(evt, handler), false)
}

/*用户操作开始事件播放*/
const handStartFunction = function (reader) {
  if (reader.state === 'disabled') {
    return false
  }

  if (!reader.isPlaying()) {
    reader.load(reader.src)
  } else {
    reader.stop()
    removeListers(document, bindFn)
  }
}

/*
语音朗读对象*/
class Reader {
  constructor(
    onLoadStart = function () {},
    onCanPlay = function () {},
    onLoadError = function () {},
    onError = function () {}
  ) {
    let self = this

    this.src = undefined
    this.state = 'waiting' /*disabeld waiting loading reading*/
    this.onLoadStart = onLoadStart
    this.onCanPlay = onCanPlay
    this.onError = onError
    this.onLoadError = onLoadError
    this.player = document.createElement('audio')
    // this.player.setAttribute('preload', 'metadata')
    this.player.setAttribute('controls', 'controls')
    this.maxDetect = 500 // 计时间隔10ms，这里设置次数

    /*绑定开始加载事件*/
    this.player.addEventListener(
      'loadstart',
      function () {
        if (self.state === 'disabled') {
          return false
        }
        self.onLoadStart()
        self.state = 'loading'
      },
      false
    )

    /*绑定可以播放事件*/
    this.player.addEventListener(
      'canplaythrough',
      function () {
        let detactor
        let count = 0

        if (self.state === 'disabled') {
          return false
        }
        try {
          console.log('play', +new Date())
          self.player.play()
        } catch (e) {
          console.log(e)
        }

        function hasPlayed() {
          count++

          if (count >= self.maxDetect) {
            self.stop()
            self.state = 'disabled'
            clearTimeout(detactor)
            removeListers(document, bindFn)
            detactor = null
            self.onError()
            return false
          }
          if (self.isPlaying()) {
            self.state = 'reading'
            /*
             * 已经开始播放了
             * 1、执行回调方法
             * 2、解绑事件注册
             * */
            self.onCanPlay()
            removeListers(document, bindFn)
            clearTimeout(detactor)
            detactor = null
          } else {
            detactor = setTimeout(function () {
              hasPlayed()
            }, 10)
          }
        }
        /*延迟检测是否已经开始播放了*/
        detactor = setTimeout(function () {
          hasPlayed()
        }, 10)
      },
      false
    )

    // 绑定加载失败的事件
    this.player.addEventListener(
      'error',
      function () {
        self.onLoadError()
        // 解绑事件
        removeListers(document, bindFn)
      },
      false
    )

    document.body.appendChild(this.player)

    /*绑定手动开始播放的事件*/
    bindFn = function (ev) {
      console.log(ev.type)
      if (self.isPlaying()) {
        return
      }
      handStartFunction(self)
    }
  }

  isPlaying() {
    return this.player.currentTime > 0
  }

  load(src) {
    console.log(`加载语音：${src}`)
    if (this.state === 'disabled') {
      return this
    }
    if (typeof src === 'boolean') {
      this.player.pause()
      this.state = 'waiting'
      return false
    }
    // console.log(src)
    if (this.src !== src) {
      this.src = src
    }
    // this.src = src
    if (!this.src) {
      return this
    }
    /*判断当前的状态*/
    if (this.state === 'stop') {
      return this
    }
    if (isFirstLoad) {
      isFirstLoad = false
      addListers(document, bindFn)
    } else {
      this.player.setAttribute('src', this.src)
      this.player.pause()
      this.player.load()
    }

    return this
  }

  play(load) {
    this.state = 'waiting'
    if (load) {
      this.load(this.src)
    }
    return this
  }

  stop() {
    this.state = 'disabled'
    this.player.pause()
    this.player.currentTime = 0
    this.src = ''
    return this
  }

  pause() {
    this.state = 'paused'
    this.player.pause()
    return this
  }
}

export default Reader
