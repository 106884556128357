<style lang="scss" scoped>
.broadcast-wrapper {
  position: fixed;
  top: $header-height + 32;
  right: 30px;
  z-index: 1000;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  &.loading {
    background-image: url('../assets/icons/voice-loading.gif');
    background-color: rgba(0, 0, 0, 0.15);
    background-size: 60%;
  }
  &.playing,
  &.pause {
    background-image: url('../assets/icons/voice-playing.png');
  }
  &.stop {
    background-image: url('../assets/icons/voice-mute.png');
  }
}
</style>

<template>
  <div class="broadcast-wrapper" :class="state" @click="toggleBroadCast"></div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { debounce } from 'lodash'
import Reader from '@/plugins/reader'

export default {
  name: 'Broadcast',
  data () {
    return {
      reader: null,
      state: 'loading'
    }
  },
  computed: {
    ...mapGetters(['displayVoice'])
  },
  watch: {
    // displayVoice: debounce(function (resource) {
    //   if (!resource) {
    //     return
    //   }
    //   const isFullUri = /^http(s)?:\/\//
    //   if (this.state !== 'stop') {
    //     if (process.env.NODE_ENV === 'development') {
    //       this.reader.load(
    //         resource.static
    //           ? resource.data
    //           : (isFullUri.test(resource.data)
    //             ? ''
    //             : process.env.VUE_APP_BASE_API) + resource.data
    //       )
    //     } else {
    //       this.reader.load(resource.data)
    //     }
    //   }
    // }, 500, { trailing: true })
    displayVoice (resource) {
      if (!resource) {
        return
      }
      const isFullUri = /^http(s)?:\/\//
      if (this.state !== 'stop') {
        if (process.env.NODE_ENV === 'development') {
          this.reader.load(
            resource.static
              ? resource.data
              : (isFullUri.test(resource.data)
                ? ''
                : process.env.VUE_APP_BASE_API) + resource.data
          )
        } else {
          this.reader.load(resource.data)
        }
      }
    }
  },
  methods: {
    toggleBroadCast () {
      if (this.state !== 'stop') {
        this.state = 'stop'
        console.log('play')
        this.reader.stop()
      } else {
        this.state = 'playing'
        console.log('play = ')
        this.reader.play()
      }
    },
    stop () {
      this.state = 'stop'
      console.log('stop')
      this.reader.stop()
    },
    pause () {
      this.state = 'pause'
      console.log('pause')
      this.reader.pause()
    },
    play () {
      this.state = 'playing'
      console.log('play = ')
      this.reader.play()
    }
  },
  created () {
    this.reader = new Reader(
      () => {
        // * 开始加载的回调函数
        this.state = 'loading'
      },
      () => {
        // * 开始朗读的回调函数
        this.state = 'playing'
      },
      () => {
        // * 资源加载失败回调
        this.state = 'playing'
      },
      () => {
        // * 初始化自动播放失败
        this.state = 'stop'
      }
    )
  }
}
</script>
